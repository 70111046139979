/**
 * Generated by orval v6.27.1 🍺
 * Do not edit manually.
 * Neubility Platform Core
 * Platform Core API Server

Production server: https://core.neubie.ai/
 * OpenAPI spec version: V1
 */

/**
 * * `LIT-1` - 고온 탐지(화재) 알림
 * `LIT-12` - 고온 탐지(흡연) 알림
 * `LIT-2` - 저온 탐지 알림
 * `LIT-3` - 가스 탐지 '이상' 알림
 * `ORA-1` - 객체(사람) 탐지 알림
 */
export type MessageTypeEnum = (typeof MessageTypeEnum)[keyof typeof MessageTypeEnum]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const MessageTypeEnum = {
  'LIT-1': 'LIT-1',
  'LIT-12': 'LIT-12',
  'LIT-2': 'LIT-2',
  'LIT-3': 'LIT-3',
  'ORA-1': 'ORA-1'
} as const
