/**
 * Generated by orval v6.27.1 🍺
 * Do not edit manually.
 * Neubility Platform Core
 * Platform Core API Server

Production server: https://core.neubie.ai/
 * OpenAPI spec version: V1
 */

/**
 * * `GAS` - 가스 센서
 * `ORA` - 객체 센서
 * `THERMAL_HIGH` - 고온 탐지(화재) 센서
 * `THERMAL_LOW` - 저온 탐지 센서
 * `THERMAL_SMOKING` - 고온 탐지(흡연) 센서
 */
export type SensorEnum = (typeof SensorEnum)[keyof typeof SensorEnum]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SensorEnum = {
  GAS: 'GAS',
  ORA: 'ORA',
  THERMAL_HIGH: 'THERMAL_HIGH',
  THERMAL_LOW: 'THERMAL_LOW',
  THERMAL_SMOKING: 'THERMAL_SMOKING'
} as const
