var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"5bdc693374a4f7f1bcf7e729bcdda16cb0f685f2"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import * as Sentry from '@sentry/nextjs'
import { ErrorUtils } from './src/utils/ErrorUtils'

const SENTRY_DSN = process.env.SENTRY_DSN

Sentry.init({
  dsn: SENTRY_DSN,
  sampleRate: 0, // 에러 이벤트를 캡쳐 (0%)
  tracesSampleRate: 0.1, // 10%의 트랜잭션을 트레이싱 (10%)
  beforeSend(event, hint) {
    const error = hint.originalException

    if (ErrorUtils.isErrorTypeInSentryError(error.name)) {
      return event
    }

    return null
  }
})
