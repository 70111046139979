/**
 * Generated by orval v6.27.1 🍺
 * Do not edit manually.
 * Neubility Platform Core
 * Platform Core API Server

Production server: https://core.neubie.ai/
 * OpenAPI spec version: V1
 */

export type NotificationsPatrolsListSensorType =
  (typeof NotificationsPatrolsListSensorType)[keyof typeof NotificationsPatrolsListSensorType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const NotificationsPatrolsListSensorType = {
  GAS: 'GAS',
  ORA: 'ORA',
  THERMAL_HIGH: 'THERMAL_HIGH',
  THERMAL_LOW: 'THERMAL_LOW',
  THERMAL_SMOKING: 'THERMAL_SMOKING'
} as const
